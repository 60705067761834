import React, {useState} from 'react';
import {ReactComponent as TwoPawsLogo} from "../assets/twoPawsLogo.svg";
import twoPawsBigLogo from "../assets/TwoPawsBigLogo.png";
import arbitrum from "../assets/arbitrum.svg";
import group from "../assets/Group-177.svg";
import optimism from "../assets/OPTIMISM-logo.svg";
import starknet from "../assets/starknet-logo.svg";
import zksync from "../assets/zksync-logo-full.svg";
import {ReactComponent as Twitter} from "../assets/twitter-icon.svg";
import {ReactComponent as Discord} from "../assets/discord-icon.svg";
import {ReactComponent as Telegram} from "../assets/telegram-icon.svg";
import {Button, Card, Col, Row} from "react-bootstrap";

const Content = () => {
    const [isLaunchMainnetButtonHovered, setIsLaunchMainnetButtonHovered] = useState(false)
    const [isLaunchArbitrumButtonHovered, setIsLaunchArbitrumButtonHovered] = useState(false)
    const randomInt = Math.floor(Math.random() * 2500)
    const randomLink = "https://nftstorage.link/ipfs/bafybeid3tcjmjj3norij4mqf5l6ijx6pdt5w2g7cxfwcwrfu5xnux5enbi/" + randomInt + ".jpg"

    return (
        <div>
            <div className="d-none d-lg-block">
                <Row>
                    <Col className="text-start" xxl={5} xl={5} lg={5} md={4} sm={0}>
                        {/*<TwoPawsText style={{}}/>*/}

                    </Col>
                    <Col xxl={5} xl={4} lg={3} md={4} sm={0}></Col>
                    <Col className="text-end" xxl={2} xl={3} lg={3} md={4} sm={12} style={{paddingRight: 70}}>
                        <Row>
                            <Col style={{cursor: "pointer"}}>
                                <a href="https://twitter.com/TwoPawsDefi" target="_blank" rel="noreferrer"><Twitter
                                    width={35} height={35}/></a>
                            </Col>
                            <Col style={{cursor: "pointer"}}>
                                <a href="https://discord.gg/czgRBMFdSX" target="_blank" rel="noreferrer"><Discord
                                    width={35} height={35}/></a>
                            </Col>
                            <Col style={{cursor: "pointer"}}>
                                <a href="https://t.me/twopawsdefi" target="_blank" rel="noreferrer"><Telegram width={35}
                                                                                                              height={35}/></a>
                            </Col>
                        </Row>
                    </Col>
                </Row></div>
            <div className="d-lg-none">
                <Row>
                    <Col className="text-center">
                        <Row>
                            <Col style={{cursor: "pointer"}}>
                                <a href="https://twitter.com/TwoPawsDefi" target="_blank" rel="noreferrer"><Twitter
                                    width={35} height={35}/></a>
                            </Col>
                            <Col style={{cursor: "pointer"}}>
                                <a href="https://discord.gg/czgRBMFdSX" target="_blank" rel="noreferrer"><Discord
                                    width={35} height={35}/></a>
                            </Col>
                            <Col style={{cursor: "pointer"}}>
                                <a href="https://t.me/twopawsdefi" target="_blank" rel="noreferrer"><Telegram width={35}
                                                                                                              height={35}/></a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <Row>
                <Col className="text-center">
                    <div className="d-none d-lg-block">
                        <img src={twoPawsBigLogo} alt="twoPawsBigLogo" width={480} height={270}/>
                    </div>
                    <div className="d-lg-none">
                        <img src={twoPawsBigLogo} alt="twoPawsBigLogo" width={260} height={135}/>
                    </div>
                    {/*<img src={twoPawsBigLogo} alt="twoPawsBigLogo" width={260} height={135}/>*/}
                    {/*<TwoPawsLogo style={{marginTop: -100}}/>*/}
                    <div className="d-none d-lg-block"><br/></div>
                    <div className="d-lg-none" style={{marginBottom: 10}}></div>

                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col className="text-center">
                    <Button
                        onMouseEnter={() => setIsLaunchMainnetButtonHovered(true)}
                        onMouseLeave={() => setIsLaunchMainnetButtonHovered(false)}
                        onClick={() => window.location.replace("https://twopaws.app")}
                        variant="dark"
                        style={{
                            backgroundColor: isLaunchMainnetButtonHovered ? "#212633" : "#3D4860",
                            color: "#d4d4ce",
                            width: 180,
                            height: 50,
                            border: "1px solid #343840",
                            borderRadius: 20
                        }}>Launch Mainnet</Button>
                </Col>
                {/*<Col className="text-center">*/}
                {/*    <Button*/}
                {/*        onMouseEnter={() => setIsLaunchArbitrumButtonHovered(true)}*/}
                {/*        onMouseLeave={() => setIsLaunchArbitrumButtonHovered(false)}*/}
                {/*        onClick={() => window.location.replace("https://arbitrum.twopaws.app/")}*/}
                {/*        variant="dark"*/}
                {/*        style={{*/}
                {/*            backgroundColor: isLaunchArbitrumButtonHovered ? "#212633" : "#3D4860",*/}
                {/*            color: "#d4d4ce",*/}
                {/*            width: 180,*/}
                {/*            height: 50,*/}
                {/*            border: "1px solid #343840",*/}
                {/*            borderRadius: 20*/}
                {/*        }}>Launch Arbitrum</Button>*/}
                {/*</Col>*/}
                <Col></Col>
            </Row>
            <div className="d-none d-lg-block"><br/></div>
            <div className="d-lg-none" style={{marginBottom: 10}}></div>
            <Row>
                <Col xl={2}></Col>
                <Col xl={8} className="text-start">
                    <Card style={{backgroundColor: "#182539", fontSize: 14}}>
                        <Card.Body>
                            <p>Total supply: 100 million tokens.</p>
                            <p>NFTDAO holders have the right to all protocol earnings after 2000 NFTDAO are sold.
                                Only DAO can add new denominations and tokens or change them.</p>

                            <p>Protocol takes 0.5% of loan amount is taken as a fee if taken order.</p>

                            <p>You will be able to place orders and earn rewards starting from 2 minutes.
                                <br/>The rewards system will be new and will be paid out if the lender fee does
                                not exceed 0%.</p>

                            <p>The rewards system will be new and will be paid out if the lender fee does not exceed 0%.</p>

                            <p>You can sell your NFTs at a price of 30k TWOPAW, but only if you create 5 orders for 1 NFT.</p>
                            <p>You can buy NFTs not only for tokens, but also for the number of created orders (default: 500). <br/>The NFT supply is unlimited.</p>
                            <p>How rewards are paid: In the case of (repay), a reward is paid out if there are sufficient free tokens on the protocol at the time of (repay).</p>


                            <p>TAX SWAP Arbitrum One Network:
                                <br/>At the start of sales will then be reduced
                                <br/>Buys less  14999 $TWOPAW are taxed at 0%
                                <br/>Buys over 14999 $TWOPAW are taxed at 5%
                                <br/>Sells less  14999 $TWOPAW are taxed at 5%
                                <br/>Sells over 14999 $TWOPAW are taxed at 10%</p>



                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={2}></Col>
            </Row>
            <Row>
                <Col xl={2}></Col>
                <Col xl={8} style={{fontSize: 20}} className="text-center">
                    <div>Token Mainnet Contract: 0xf7de6DEf3D319811418d69Bf56c532A815FC47e8</div>
                    <div>Token Arbitrum Contract: 0x3Ce724447cBa503a3804Cb31eceDCf39eE86567b</div>
                </Col>
                <Col xl={2}></Col>
            </Row>
            <div className="d-none d-lg-block"><br/></div>
            <div className="d-lg-none" style={{marginBottom: 10}}></div>
            <Row>
                <Col xl={2}></Col>
                <Col xl={8} style={{fontSize: 15}}>
                    <div className="d-none d-lg-block text-center" style={{marginBottom: 10}}>
                        <div>Coming soon on:</div>
                        <span> <a href="https://www.optimism.io/" target="_blank"><img src={optimism} alt="optimism"
                                                                                       width={100}
                                                                                       style={{paddingLeft: 10}}/></a></span>
                        <span> <a href="https://starknet.io/" target="_blank">
                            <img src={group} alt="group" width={45} style={{paddingLeft: 10}}/>
                            <img src={starknet} alt="starknet" width={80} style={{paddingLeft: 10}}/>
                        </a></span>
                        <span> <a href="https://zksync.io/" target="_blank"><img src={zksync} alt="zksync" width={100}
                                                                                 style={{paddingLeft: 10}}/></a></span>
                    </div>
                    <div className="d-lg-none text-center" style={{marginBottom: 10}}>
                        <div>Coming soon on:</div>
                        <span> <a href="https://www.optimism.io/" target="_blank"><img src={optimism} alt="optimism"
                                                                                       width={80}
                                                                                       style={{paddingLeft: 10}}/></a></span>
                        <span> <a href="https://starknet.io/" target="_blank">
                            <img src={group} alt="group" width={45} style={{paddingLeft: 10}}/>
                            <img src={starknet} alt="starknet" width={80} style={{paddingLeft: 10}}/>
                        </a></span>
                        <span> <a href="https://zksync.io/" target="_blank"><img src={zksync} alt="zksync" width={80}
                                                                                 style={{paddingLeft: 10}}/></a></span>
                    </div>

                </Col>
                <Col xl={2}></Col>
            </Row>
        </div>
    );
};

export default Content;