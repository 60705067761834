import Header from "./components/Header/Header";
import Content from "./components/Content/Content";
import {Container} from "react-bootstrap"

function App() {
  return (
    <div style={{width: "100%"}}>
        <Container>
            <Content/>
        </Container>
    </div>
  );
}

export default App;
